import React from "react";
import { body } from "../../../commonStyles/Fonts";
import { styled } from "../../../../stitches.config";
import Link from "../../../../components/Link";

export interface DropdownDesktopProps {
    links: Array<{
        url?: string;
        text?: string;
    }>;
}

const DropdownDesktop: React.FC<DropdownDesktopProps> = ({
    links
}) => {
    return (
        <Container className="dropdown-menu-desktop">
            <Wrap className={body()}>
                <Arrow />
                {links?.map((link, id) => (
                    <Link key={id} to={link.url}>{link.text}</Link>
                ))}
            </Wrap>
        </Container>
    );
};

const Container = styled("div", {
    display: "none",
    position: "absolute",
    zIndex: 100,
    "&:hover": {
        display: "block !important",
    }
})

const Wrap = styled("div", {
    background: "$darkmodeBg",
    color: "$grayscale0Fixed",
    padding: "24px 24px 8px 24px",
    zIndex: 100,
    position: "relative",
    marginTop: "40px",
    minWidth: "max-content",

    "& a": {
        marginBottom: "16px",
        display: "block",
    }
})

const Arrow = styled("div", {
    width: "13px",
    height: "13px",
    position: "absolute",
    display: "block",
    background: "$darkmodeBg",
    top: "-3.5px",
    left: "24px",
    transform: "rotate(45deg)",
})

export default DropdownDesktop;
