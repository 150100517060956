import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { defaultColors, styled } from "../../../stitches.config";

import Link from "../../../components/Link";
import Logo from "../../atoms/Logo";
import AudioBrief from "./AudioBrief";
import ToggleSwitch from "../../ToggleSwitch";
import { useStores } from "../../../helpers";

import { bodySmall, h4 } from "../../commonStyles/Fonts";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg?react";
import { ReactComponent as NavMenuIcon } from "../../assets/icons/nav-menu-icon.svg?react";
import { ReactComponent as AccountIcon } from "../../assets/icons/myaccount.svg?react";
import { ColourModeContext } from "../StitchesThemeProvider";
import { useAuth } from "../../../context/AuthContext";

import DropdownDesktop from "./DropdownMenu/DropdownDesktop"

import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../../../components/common/constants/constants"

import NavLogo from "./NavLogo"

import BrandsConfig from "../../../config"
import NoSSR from "../../../helpers/NoSSR";

const CATEGORIES = window.APP_SETTINGS.nav_categories;

const SearchBar = React.lazy(() => import(/* webpackChunkName: "Navbar__SearchBar" */"./SearchBar"));
const Notifications = React.lazy(() => import(/* webpackChunkName: "Navbar__Notifications" */"./Notifications"));
const NavMenuMobile = React.lazy(() => import(/* webpackChunkName: "Navbar__NavMenuMobile" */"./NavMenuMobile"));

const TOP_BAR_ATOMS = Object.entries(BrandsConfig).reduce((atoms, [brandName, brandConfig]) => {
  atoms[brandName as Config.Brand] = (
    <Link to={`https://${brandName}.pl`}>
      {brandConfig.brandTitle}
    </Link>
  )

  return atoms;
}, {} as { [key in Config.Brand]: ReactNode });

export const Navbar: React.FC = () => {
  const [{ user }] = useAuth();
  const [isSearchBar, setSearchBar] = React.useState(false);
  const [isBagdes, setBadges] = React.useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = React.useState(false);
  const { pathname } = useLocation();
  const { UIStore } = useStores();

  React.useEffect(() => {
    setSearchBar(false);
  }, [pathname]);

  const toggleSearchBar = () => setSearchBar((prev) => !prev);
  const toggleBadges = () => setBadges((prev) => !prev);

  const { [window.BRAND]: mainService, ...otherServices } = TOP_BAR_ATOMS;

  const activeMobileMenuHandler = () => setIsMobileMenuActive(!isMobileMenuActive);

  const navigate = useNavigate();

  const handleZenToggle = () => {
    if (!user || !user.valid) {
      UIStore.setShowLoginModal(true);
    } else {
      navigate('/dashboard');
    }
  }

  return (
    <NavContainer className="navigation-top--wrapper">
      <Nav className="nav-fixed">
        <TopBar>
          <TopBarWrapper className="badges">
            <Badge color>{mainService}</Badge>
            {Object.entries(otherServices).map(([key, value]) => (
              //@ts-ignore
              <Badge key={key}>{value}</Badge>
            ))}
          </TopBarWrapper>
          <TopBarWrapper className="badges-mobile">
            <button onClick={toggleBadges} className="toggleBadges">
              {mainService}
              <span className={isBagdes ? "badge-arrow badge-arrow-active" : "badge-arrow"}>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.556641 1.1324L1.26375 0.425293L4.99858 4.16013L8.73342 0.425293L9.44053 1.1324L4.99858 5.57434L0.556641 1.1324ZM8.73332 0.602172L9.26357 1.13242L4.9985 5.39749L4.99858 5.39756L9.26375 1.1324L8.73342 0.60207L8.73332 0.602172ZM1.26365 0.602171L4.9984 4.33693L4.9985 4.33683L1.26375 0.60207L1.26365 0.602171Z" />
                </svg>
              </span>
            </button>
            <div className={isBagdes ? "badges-wrap badges-wrap-active" : "badges-wrap"}>
              {Object.entries(otherServices).map(([key, value]) => (
                <Badge className="badge" key={key}>{value}</Badge>
              ))}
            </div>
          </TopBarWrapper>
          <TopBarWrapper className="toggles">
            {!DISABLE_PREMIUM_FLAG_ACTIVE &&
              <NoSSR>
                <div className={bodySmall()}>
                  Twoje na:Temat
                  <ToggleSwitch
                    active={user ? user.valid : false}
                    color="white"
                    size="small"
                    onToggle={handleZenToggle}
                  />
                </div>
              </NoSSR>
            }
            <ColourModeContext.Consumer>
              {(context: any) => (
                <div className={bodySmall()}>
                  Tryb Ciemny
                  <ToggleSwitch
                    active={context.isDarkMode}
                    color="white"
                    size="small"
                    onToggle={context.cycleToggleMode}
                    dataAnalyticsName="nav-dark-mode"
                  />
                </div>
              )}
            </ColourModeContext.Consumer>
          </TopBarWrapper>
        </TopBar>
        <NavWrap className="navigation-top--second-line">
          <Link to="/" data-analytics-name="nav-main-logo">
            <NavLogo
              className="navigation-top--logo"
              brand={window.BRAND as any}
              type="dark"
              isFullNav={true}
            />
          </Link>
          {isSearchBar ? (
            <Suspense fallback={null}>
              <SearchBar toggleSearch={toggleSearchBar} />
            </Suspense>
          ) : (
            <Categories>
              {CATEGORIES.slice(0, 10).map((category, i) => (
                <Category key={category.name}>
                  <Link to={category.url} data-analytics-name={"nav-category-nr-" + i + "-" + category.name}>
                    {category.navicon && <Navicon src={category.navicon} />}
                    {category.name}
                  </Link>
                  {category.links && <DropdownDesktop links={category.links} />}
                </Category>
              ))}
            </Categories>
          )}
          <Options>
            {!isSearchBar && (
              <button onClick={toggleSearchBar} data-analytics-name="nav-search-icon">
                <SearchIcon />
              </button>
            )}
            <WrapperNotifications>
              <NoSSR fallback={null}>
                <Notifications />
              </NoSSR>
            </WrapperNotifications>
            {!DISABLE_PREMIUM_FLAG_ACTIVE &&
              <Link to="/dashboard" data-analytics-name="nav-dashboard">
                <AccountIcon className="accountIcon" />
              </Link>
            }
          </Options>
        </NavWrap>
        <CategoriesMobile>
          <button data-analytics-name="nav-mobile-categories-menu-icon">
            <NavMenuIcon onClick={activeMobileMenuHandler} />
          </button>
          {CATEGORIES.slice(0, 10).map((category) => (
            <Category key={category.name}>
              <Link to={category.url}>{category.name}</Link>
            </Category>
          ))}
        </CategoriesMobile>
        <Suspense fallback={null}>
          <NavMenuMobile
            isMobileMenuActive={isMobileMenuActive}
            setIsMobileMenuActive={setIsMobileMenuActive}
          />
        </Suspense>
        <AudioBrief type="top" />
      </Nav>
    </NavContainer>
  );
};

// top bar

const Navicon = styled("img", {
  height: '20px',
  marginRight: '6px',
  display: 'inline-block',
  float: 'left'
});

const TopBar = styled("div", {
  backgroundColor: "$primary",
  whiteSpace: "nowrap",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  height: "32px",
  display: "flex",
  "@bp4": {
    height: "34px",
    overflowX: "auto",
    justifyContent: "space-between",
  },
  "&::-webkit-scrollbar": {
    background: "transparent",
    height: 0,
  },
  ".badges": {
    display: "none",
    "@bp4": {
      display: "flex",
    }
  },
  ".badges-mobile": {
    position: "relative",
    "@bp4": {
      display: "none"
    },
    "button": {
      height: "32px",
      minWidth: "100px",
      background: "#131212",
      borderWidth: 0,
      fontFamily: "$grotesk",
      fontWeight: 700,
      textAlign: "left",
      padding: "8px 0px 8px 0px",
      fontSize: 12,
      lineHeight: "16px",
      borderRight: "1px solid $primary120",
      "& span": {
        color: "$primary",
      },
      "& a": {
        pointerEvents: "none",
        margin: "0 0 0 16px",
      }
    },
    ".badges-wrap": {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "32px",
      zIndex: 10,
      backgroundColor: "$primary",
      maxHeight: "0px",
      overflow: "hidden",
    },
    ".toggleBadges": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".badge-arrow": {
      //backgroundImage: `url(${ArrowIcon})`,
      display: "inline-flex",
      margin: "0 16px 0 8px",
      "svg": {
        fill: "$primary",
      }
    },
    ".badge-arrow-active": {
      "svg": {
        transform: "rotate(180deg)",
      }
    },
    ".badges-wrap-active": {
      maxHeight: "300px",
      width: "100%",
    },
    ".badge": {
      borderRight: "0px solid $primary120",
      borderBottom: "1px solid $primary120",
      padding: "8px 16px",
      width: "100%",
    }
  },
  ".toggles": {
    marginLeft: "auto",
  }
});

const TopBarWrapper = styled("div", {
  display: "flex",
  "& div": {
    color: "$grayscale0Fixed",
    marginRight: "18px",
    display: "flex",
    alignItems: "center",
    "@bp4": {
      marginRight: "24px",
    },
    "& button": {
      marginLeft: "8px",
    },
  },
});

const Badge = styled("span", {
  fontFamily: "$grotesk",
  fontWeight: 700,
  display: "inline-block",
  padding: "8px 24px 8px 16px",
  fontSize: 12,
  lineHeight: "16px",
  borderRight: "1px solid $primary120",
  "&:hover": {
    backgroundColor: "$primary120",
  },
  "@bp4": {
    padding: "9px 24px",
  },
  "& a": {
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    }
  },
  variants: {
    color: {
      true: {
        backgroundColor: "#131212",
        "& span": {
          color: "$primary",
        },
        "&:hover": {
          backgroundColor: "#131212",
        },
      },
    },
  },
});

// main bar

const Categories = styled("div", {
  display: "none",
  flexDirection: "row",
  marginRight: "auto",
  alignItems: "center",
  "@bp4": {
    display: "flex",
  },
});

const CategoriesMobile = styled("div", {
  display: "flex",
  flexDirection: "row",
  background: "$grayscale100Fixed",
  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  padding: "11px 0 13px 0",
  "&::-webkit-scrollbar": {
    background: "transparent",
    height: 0,
  },
  "@bp4": {
    display: "none",
  },
  "& span": {
    flexShrink: "0",
  },
  "& button": {
    height: "100%",
    background: "none",
    borderWidth: 0,
    padding: "0 24px",
    flexShrink: "0",
  },
});

const Category = styled("span", {
  fontSize: 14,
  fontFamily: "$grotesk",
  fontWeight: "$6",
  // lineHeight: "$18",
  lineHeight: '20px',
  display: "inline-block",
  marginRight: 24,
  position: "relative",
  "& a": {
    color: "$grayscale0Fixed",
    textDecoration: "none",
    transition: "0.2s",
    "&:hover": {
      color: "$primary !important",
      textDecoration: "none",
    },
  },
  "&:hover": {
    "& .dropdown-menu-desktop": {
      display: "block",
    }
  },
});

const Options = styled("div", {
  display: "flex",
  "& button, a": {
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    borderWidth: "0px",
    width: "48px",
    ".accountIcon": {
      "&:active": {
        "& path": {
          fill: "$primary",
        }
      },
    },
    "@bp4": {
      width: "68px",
      borderLeft: "1px solid rgba(255, 255, 255, .1)",
      ".accountIcon": {
        "&:hover": {
          "& path": {
            fill: "$primary",
          }
        },
      }
    },
    "& svg": {
      width: 20,
      height: 20,
    },
  },
});

const NavWrap = styled("div", {
  height: 52,
  width: "100%",
  backgroundColor: defaultColors.grayscale100,
  a: {
    color: `${defaultColors.grayscale0}`,
    "&:focus": {
      color: "$primary",
      textDecoration: "none",
    }
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1px solid rgba(255, 255, 255, .1)",
  "@bp4": {
    height: 84,
    justifyContent: "flex-start",
  },
  ".navigation-top--logo": {
    marginLeft: "20px",
    "@bp4": {
      marginLeft: 0,
    }
  }
});

const Nav = styled("nav", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
});

const NavContainer = styled("div", {
  // zmiana position z relative na static, żeby tapeta była klikalna a nawigacja na 100% szerokości
  position: "static",
  height: 0,
  paddingBottom: "128px",
  "@bp4": {
    paddingBottom: "118px",
  },
});

const WrapperNotifications = styled("div", {
  width: "48px",
  display: "flex",
  "@bp4": {
    width: "68px",
  },
});

export default Navbar;
